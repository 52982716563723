@import "./styles/main";

.Transition {
  position: fixed;
  background: url("./images/transition_sprite.png") 0px 0px;
  animation-name: transition;
  // background-color: rgba(0,0,0,0.45);
  animation-timing-function: steps(37);
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  transform-origin: left top;
  z-index: 2;
  pointer-events: none;
  top: 0;
  left: 0;
}

@keyframes transition {
  0% {
    opacity: 1;
    background-position: -10px 0;
  }
  90% {
    opacity: 1;
    background-position: -22950px 0px;
  }
  100% {
    opacity: 0;
    background-position: -22950px 0px;
  }
}
.App {
  // top: 0;
  // left: 0;
  // position: relative;
  // height: 100%;

  &__Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: var(--spacing);
    z-index: 3;

    @include print {
      display: none;
    }

    a { display: inline-block;}

    img {
      height: 67px;
    }
  }
}