@import "../../styles/main";

.VideoThumbnail {
  position: relative;
  width: 100%;
  transition: transform 500ms ease;
  will-change: transform;
  display: block;
  
  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    margin: var(--spacing) 0;
    transition: opacity 250ms ease;

    &:first-of-type {
      position: absolute;
      width: 100%;
    }
  }
  svg {
    position: absolute;
    width: calc(100% + 20px);
    transform: translate(-10px, -10px);
    transform-origin: center center;
    z-index: 1;
    margin: var(--spacing) 0;
  }

  path {
    fill: none;
  }

  &:hover {
    img:first-of-type {
      opacity: 0;
    }
  }
}

.Picture {
  display: inline-block;
  min-height: 178px;
}
