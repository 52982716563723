@import '../../styles/main';

.DynamicLine {
  width: 100%;
  position: absolute;
  fill: none;
  stroke: $text-highlight-color;
  bottom: -3px;
  left: 0;

  &:last-of-type {
    bottom: -6px
  }
}
