.Bubbles {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  touch-action: none;
  width: 100%;
  height: 100%;
}
