@import "../../styles/main";

.Home {
  position: fixed;
  width: 100vw;
  left: 0;
  height: 100vh;
  top: 0;
  z-index: -1;
  position: fixed;

  canvas {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.IntroWrapper {
  max-width: calc(100% - 300px);
  margin-top: var(--header-height);
  margin-right: 0;
  margin-left: auto;
  position: relative;

  @include mobile {
    max-width: 100%;
    h1 {
      text-align: center;
    }
  }
}

.SwimmingKrystal {
  * {
    pointer-events: none;
    user-select: none;
    width: 300px;
    max-width: 100%;
    animation-direction: alternate-reverse;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: float;
    animation-timing-function: ease-in-out;
    transform-origin: center center;
    top: 142px;
    position: relative;

    @include mobile {
      top: calc(100vh - 500px);
      left: calc(50% - 236px);
      max-width: 400px;
      width: 400px;
    }

    @include small-mobile {
      top: calc(100vh - 400px);
      left: calc(50% - 236px);
      max-width: 400px;
      width: 400px;
    }
  }
}
