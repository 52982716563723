$background-color: #061424;
$shadow-color: #b6d331;
$text-highlight-color: #00ffa4;
$text-hover-color: $shadow-color;
$highlight-color: #41a1cc;
$text-subdue-color: rgba(255, 255, 255, 0.6);
$text-color: #fff;

:root {
  --header-height: 50px;
  --top-distance: 80px;
  --spacing: 20px;
  --spacing-tight: 10px;
  --spacing-loose: 40px;
  --text-highlight-color: #{$text-highlight-color};
  --highlight-color: #{$highlight-color};
  --hover-color: #{$shadow-color};
  --shadow-color: #{$shadow-color};
  --p-font: "Courier New", monospace;
  --heading-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
