@import "../../styles/main";

.Contact {
  position: relative;
  display: block;
  width: 100%;
  padding: var(--spacing-loose);
  max-width: 960px;
  margin: 0 auto;
  margin-top: var(--top-distance);
  overflow: hidden;

  @include mobile {
    padding: 0 var(--spacing);
    padding-top: var(--spacing);
  }

  article {
    margin-top: var(--spacing-loose);
  }
}

.ContentWrapper {
  z-index: 1;
  position: relative;
}

.Shark {
  width: 817px;
  height: 410px;
  background-image: url(../../images/shark.png);
  background-position-x: 12255px;
  background-position-y: 0px;
  animation-name: shark;
  transform: scale(0.5) translate(0);
  transform-origin: left;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(15);
  animation-direction: alternate;
}

.SharkWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  animation-name: sharkSwim;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 0;

  @include mobile {
    animation-duration: 10s;
  }
  @include tablet {
    animation-duration: 20s;
  }
}

@keyframes shark {
  from {
    background-position: 0 0;
    transform: scale(0.5) translate(0);
  }
  to {
    transform: scale(0.5) translate(10px, 10px);
    background-position: -12255px 0;
  }
}
@keyframes sharkSwim {
  from {
    transform: translate(100vw, 0);
  }
  to {
    transform: translate(calc(-100vw - 410px), -100px);
  }
}
