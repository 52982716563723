@import '../../styles/main';

.SwimmingKrystal {
  * {
    pointer-events: none;
    user-select: none;
    width: 300px;
    max-width: 100%;
    animation-direction: alternate-reverse;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: float;
    animation-timing-function: ease-in-out;
    transform-origin: center center;
    position: relative;
    top: -150px;
    
    @include mobile {
      top: 30px;
    }
  }
}
