@import "./grid-settings";
@import "./common";

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  padding: 0;
  margin: 0;
  background: $background-color;
  background: linear-gradient(0deg, #010b18, #061424);
  background-attachment: fixed;
}

body {
  font-family: "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  line-height: 1.3;

  @include print {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
  }
}

h1 {
  font-family: var(--heading-font);
  margin: 0;
}

h2 {
  font-size: 1.4rem;
  margin: 0;
}

h3 {
  font-size: 1.2rem;
  margin: 0;
  color: $text-subdue-color;
}

time {
  color: $text-subdue-color;
}

p {
  line-height: 1.5;
  // text-shadow: 0px 1px 2px $background-color, 0px 1px 13px $background-color,
  // 0px 0px 13px #061424, 0px 1px 35px #1281a9;
}

a {
  color: var(--text-highlight-color);
  display: block;
  text-decoration-color: var(--highlight-color);
  text-decoration-style: dashed;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;

  &:hover {
    text-decoration-color: var(--hover-color);
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
    color: var(--hover-color);
  }
}

button,
.Button {
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  color: $text-highlight-color;
  font-family: inherit;
  font-size: inherit;
  display: inline-block;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px 0;
}

hr {
  margin: 15px 0;
  display: block;
  width: 100%;
  height: 1px;
  border: none;
  background: $highlight-color;

  @include print {
    background: black;
    border-bottom: 1px solid black;
    margin: 5px 0 0 0;
  }
}

@keyframes float {
  0% {
    transform: translate(-10px, -30px) scale(1) rotate(0);
  }
  100% {
    transform: translate(0) scale(0.98) rotate(-5deg);
  }
}

.SectionTitle {
  color: var(--highlight-color);
  font-weight: 300;
  font-size: 16px;
  border-top: 1px solid;
  padding-top: var(--spacing);
}

.-spaceTop {
  margin-top: var(--spacing);
  padding-top: var(--spacing-tight);
}

.imagePlaceholder {
  width: 100%;
  height: 150px;
  background: var(--highlight-color);
  display: block;
}

.textPlaceholder {
  span {
    width: 100%;
    height: 10px;
    display: block;
    background: var(--highlight-color);
    border-radius: 5px;
    margin-top: var(--spacing);

    &:last-of-type {
      width: 50%;
    }
  }
}

.BackgroundSkeleton {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../images/background.jpg");
  background-size: cover;
}

.PageWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #41a1cc5c;
  border-radius: 10px;

  &:hover {
    background: $highlight-color;
  }
}
