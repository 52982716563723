@import "../../styles/main";

$menuTransition: transform .5s cubic-bezier(0.4, 0, 0.2, 1), opacity .7s cubic-bezier(0.4, 0, 0.2, 1);

.Menu {
  background: linear-gradient(45deg, rgb(1, 7, 10), rgba(2, 14, 22, 0.8));
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  transition: $menuTransition;
  display: flex;
  flex-direction: column;
  justify-content: center;
  li {
    opacity: 0;
    transition: $menuTransition;
    transform: translateX(200px);
  }
}

.isOpen {
  transform: translateX(-500px);
  transition: all 0.4s ease-in-out;
  opacity: 1;

  @include mobile {
    transform: translateX(-100vw);
  }

  li {
    transform: translateX(0);
    opacity: 1;

    @for $i from 2 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i * 60}ms;
      }
    }
  }
}

.Button {
  position: fixed;
  top: 19px;
  right: 21px;
  z-index: 2;
  cursor: pointer;
  background: #092433;
  padding: 10px;
  border-radius: 5px;
  width: 57px;

  rect {
    transition: transform 0.35s cubic-bezier(0.43, -0.3, 0.51, 1.38);
    transform-origin: center center;
    fill: var(--text-highlight-color);
  }

  &:hover {
    color: var(--hover-color);

    rect {
      fill: var(--hover-color);
    }
  }
}

.Nav {
  display: flex;
  flex-direction: column;
  font-family: var(--p-font);
  font-size: 4rem;
  padding-left: 5vw;
  text-transform: uppercase;

  @include mobile {
    font-size: 3rem;
  }

  a {
    text-decoration-color: var(--highlight-color);
    text-decoration-style: dashed;
    text-decoration-thickness: 2px;
    text-underline-offset: 7px;
    position: relative;

    &:hover {
      text-decoration-color: var(--hover-color);
      text-decoration-style: solid;
      text-decoration-thickness: 3px;
      color: var(--hover-color);

      svg {
        stroke: var(--hover-color);
      }
    }

    svg {
      top: 9px;
      position: absolute;
      right: -29px;
      stroke: var(--highlight-color);
    }
  }
}
