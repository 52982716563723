@import '../../styles/main';

.Projects{
  position: relative;
  top: calc(100vh + var(--header-height));
  min-height: 100vh; 
  width: 100%;
  padding: var(--spacing-loose);
  max-width: 960px;
  margin: 0 auto;
  padding-top: 0;
  
  @include mobile {
    padding: 0 var(--spacing);
    height: calc(100vh - var(--header-height));
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--spacing-loose);
    
    @include mobile {
      grid-gap: var(--spacing);
      grid-template-columns: 1fr;
    }

    @include small-mobile {
      li:last-child {
        display: none;
      }
    }
  }
}


.CTA {
  margin-top: var(--spacing);
  p {
    padding-bottom: var(--spacing-tight);
  }
}