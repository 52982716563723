@import "../../styles/main";

.Button {
  position: relative;
  display: inline-block;
  color: $text-highlight-color;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
    color: var(--hover-color);
    text-shadow: 0 0 20px var(--hover-color);
  }

  svg {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: dash 2s ease-in-out forwards;
    animation-delay: 250ms;
  }

  svg:last-child {
    animation-delay: 500ms;
    margin-top: 7px;
    max-width: 90%;
    margin-left: 5%;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
