@import "../../styles/main";

.Print {
  background: $text-highlight-color;
  color: $background-color;
  display: inline-block;
  position: absolute;
  right: 0;
  font-size: 14px;
  padding: 10px 11px;
  top: 18px;
  font-weight: bold;
  border-radius: 5px;;
  transition: all 250ms ease-in-out;

  @include print {
    display: none;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px #00ffa461;
  }
  @include mobile { display: none; }

  &__icon {
    fill:$background-color;
    width: 16px;
    margin-right: 10px;
    float: left;
  }
}

.Company {
  border-left: 1px solid $highlight-color;
  color: $highlight-color;
  float: left;
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 10px;
  margin-top: 15px;
  padding-left: 10px;
  text-align: left;

  @include print {
    color: black;
    font-size: 12px;
    margin-top: 5px;
  }
}

.Position {
  color: $text-highlight-color;
  float: left;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 15px;
  text-align: left;
  text-transform: uppercase;

  @include print {
    color: black;
    font-size: 12px;
    margin-top: 5px;
  }
}

.Certification {
  color: $text-color;
  float: left;
  width: 100%;
  font-weight: 900;
  margin: 10px 0 0 0;
  text-align: left;

  @include print {
    color: black;
    font-size: 12px;
  }
}

.Bio {
  // @include span-columns(4 of 12);
  text-align: center;
  padding-bottom: var(--spacing);

  // @include media($tablet) {
  // @include span-columns(12 of 12);
  // };

  img {
    max-width: 170px;
  }
}

.Contact {
  float: left;
  font-size: 12px;
  margin: 0;
  padding: var(--spacing-tight) 0;
  text-align: left;
  width: 100%;

  &_icon {
    fill: $highlight-color;
    width: 9px;
    margin-right: 10px;
  }

  @include print {
    color: black;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
  }
}

.Resume {
  max-width: 960px;
  margin: 0 auto;
  padding: var(--spacing);
  display: grid;
  grid-template-columns: 230px auto;
  grid-gap: var(--spacing-loose);
  padding-top: var(--top-distance);
  padding-bottom: var(--spacing-loose);
  
  @include mobile {
    grid-template-columns: auto;
  }

  hr {
    float: left;
  }


  &__col {
    text-align: center;
    padding: 0;
    position: relative;
  }

  &__languages {
    text-align: left;

    @include print {
      text-align: left;
      padding-top: 10px;
      float: left;
      width: 100%;
    }

    &_item {
      float: left;
      font-size: 14px;
      font-weight: bold;
      margin-top: 15px;
      width: 100%;
      text-transform: uppercase;

      &:last-child {
        margin-bottom: 40px;

        @include print {
          margin-bottom: 15px;
        }
      }

      span {
        float: left;
        font-size: 12px;
        font-weight: lighter;
        width: 100%;
        text-transform: none;
      }
    }
  }

  &__section_title {
    float: left;
    font-size: 26px;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
    width: 100%;

    @include print {
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }

  &__wrapper {
    float: left;
    width: 100%;
  }

  &__picture {
    border-radius: 250px;
    margin-top: 25px;
    border: 10px solid rgba(255, 255, 255, 0.14);
  }

  &__title {
    font-size: 16px;
  }

  &__headline {
    text-transform: none;
    font-size: 14px;
  }

  &__text {
    white-space: pre-wrap;
    text-align: left;
    float: left;
    width: 100%;
    font-size: 14px;

    @include print {
      font-size: 12px;
      margin: 6px 0;
    }
  }

  &__date {
    font-weight: lighter;
    float: left;
    width: 100%;
    text-align: left;
    color: gray;
    margin: 10px 0 0 0;
    padding: 0;

    @include print {
      color: rgba(black, 0.4);
      font-size: 12px;
      margin: 5px 0;
    }
  }
}
