@import "../../styles/main";

.Intro {
  border-radius: 6px;
  padding: 20px 0 50px;
  top: 10vh;
  width: 70%;
  max-width: 800px;
  margin-top: var(--header-height);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  p {
    padding-bottom: 25px;
  }

  button svg {
    bottom: -15px;

    &:last-of-type {
      bottom: -20px;
    }
  }

  @include mobile {
    padding: var(--spacing) var(--spacing-loose);
    padding-bottom: var(--spacing-loose);
    margin: 0;
    max-width: 100%;
    width: 100%;
    text-align: center;

    button {
      max-width: 200px;

      svg {
        bottom: -10px;

        &:last-of-type {
          bottom: -15px;
        }
      }
    }
  }

  h1 {
    background-image: url("../../images/text_bg.jpg");
    background-size: cover;
    font-size: calc(25px + (200 - 25) * (100vw - 400px) / (2700 - 400));
    font-weight: 700;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
  }
}
