$small-mobile-height: 750px;
$mobile-width: 768px;
$tablet-width: 1024px;

:root {
  --small-mobile-height: #{$small-mobile-height};
  --mobile-width: #{$mobile-width};
  --tablet-width: #{$tablet-width};
}

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin small-mobile {
  @media (max-height: #{$small-mobile-height}) and (max-width: #{$mobile-width}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin print {
  @media  only print {
    @content;
  }
}