@import '../../styles/main';

.Contact {
  display: grid;
  grid-template-columns: repeat(5, 50px);
  grid-gap: var(--spacing);

  img {
    width: 50px;
    transition: all 250ms ease-in-out;

    &:hover {
      transform: scale(1.09);
      color: var(--hover-color);
      box-shadow: 0 0 10px -1px #000000, 0 0 30px -1px rgb(9, 54, 106);
    }
  }
}