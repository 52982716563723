@keyframes Spinner {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.Loading {
 width: 100%;
 height: 100%;
 position: fixed; 
//  background: #061424;
 display: flex;
 align-items: center;
 justify-content: center;
 color: white;
 text-transform: uppercase;
 font-weight: 900;
 font-size: 3em;
 text-align: center;
}
.Spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Spinner div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: Spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.Spinner div:nth-child(2) {
  animation-delay: -0.5s;
}

